import UiAdapter from 'Engine/base/ui/uiAdapter/BaseUiAdapter';
import eStateTypes from '../../enums/eStateTypes';
import EntryPoint from 'Engine/EntryPoint';

export default class UiAdapterExtended extends UiAdapter {
  init(config) {
    super.init(config);
    const soundMuted = Boolean(EntryPoint.GameSettings.soundMute) && Boolean(EntryPoint.GameSettings.winningMute);
    EntryPoint.GameSettings.soundMute = soundMuted;
    EntryPoint.GameSettings.winningMute = soundMuted;
    this.ControllerUi.setSoundMuted(soundMuted);
  }

  updateSoundMuted() {
    const soundMuted = Boolean(EntryPoint.GameSettings.soundMute) && Boolean(EntryPoint.GameSettings.winningMute);
    this.ControllerUi.setSoundMuted(soundMuted);
  }

  extraStateHandlers = {
    [eStateTypes.EST_JACKPOT_GAME_IDLE]: () => {
      this.hideUi();
    },
    [eStateTypes.EST_SHOW_WON_JACKPOT_GAME]: () => {
      this.hideUi();
    },
    [eStateTypes.EST_JACKPOT_GAME_OUT]: () => {
      this.showUi();
      this.ControllerUi.setWin(EntryPoint.GameModel.jackpotWinAmount + (EntryPoint.GameModel.totalWin || 0));
    },
    default: () => {
    },
  };
}
